import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "../components/globals/seo"
import Layout from "../components/containers/layout"
import Section from "../components/containers/section"
import Container from "../components/containers/container"
import CategoryList from "../components/globals/categoryList"
import FaqBannerLarge from "../components/banners/faqBannerLarge"

const FAQPage = ({ data }) => {
  const categories = [data.general, data.apps, data.parents, data.coaches]

  const filteredCategories = categories.filter(item => {
    return item.edges.length >= 1
  })

  return (
    <Layout navbarPadOnly>
      <SEO
        title="FAQs"
        description="Find answers to common questions about TopTekkers."
      />

      <FaqBannerLarge data={data} />

      <Section pt={[26, null, 80]}>
        <Container>
          <CategoryList categories={filteredCategories} />
        </Container>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  query {
    general: allPrismicFaq(
      limit: 4
      filter: { data: { category: { eq: "general" } } }
    ) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
          }
        }
      }
    }
    apps: allPrismicFaq(
      limit: 4
      filter: { data: { category: { eq: "apps" } } }
    ) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
          }
        }
      }
    }
    parents: allPrismicFaq(
      limit: 4
      filter: { data: { category: { eq: "parents" } } }
    ) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
          }
        }
      }
    }
    coaches: allPrismicFaq(
      limit: 4
      filter: { data: { category: { eq: "coaches" } } }
    ) {
      edges {
        node {
          uid
          data {
            category
            title {
              text
            }
          }
        }
      }
    }
    profpicBlue: file(
      relativePath: { eq: "images/illustration-profpic-blue.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 166) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    profpicRed: file(
      relativePath: { eq: "images/illustration-profpic-red.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 166) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    profpicPink: file(
      relativePath: { eq: "images/illustration-profpic-pink.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 96) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    profpicOrange: file(
      relativePath: { eq: "images/illustration-profpic-orange.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 166) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

FAQPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FAQPage
